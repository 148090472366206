.score-button {
  width: 50px;
  color: white;
}

.green-button {
  background-color: #4caf50;
}

.orange-button {
  background-color: #ff9800;
  margin: 0 10px;
}

.red-button {
  background-color: #f44336;
}

.category-button {
  color: white;
  width: 80px;
  height: 50px;
  margin-right: 15px;
}

.cat1-button {
  background-color: #2196f3;
}

.cat2-button {
  background-color: #3f51b5;
}

.circle-button {
  background-color: #607d8b;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  margin: 0px 5px;
  font-size: 14px;
}

.first-strike {
  /* background-color: red; */
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
  margin: 0px 5px;
}

.score-panel {
  padding: 0px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  /* position: relative; */
  border: 1px solid black;
}

.team-name-blue {
  border-bottom: 1px solid black;
  margin-bottom: 0px;
  font-size: 16px;
  color: blue;
  font-weight: 700;
  background-color: lightgray;
}
.team-name-red {
  border-bottom: 1px solid black;
  margin-bottom: 0px;
  font-size: 16px;
  color: red;
  font-weight: 700;
  background-color: lightgray;
}

.player-name {
  border-bottom: 1px solid black;
  /* margin-bottom: 5px; */
  font-size: 14px;
  color: black;
  font-weight: 700;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(20, 30px);
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

.number-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
}

.diagonal-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
}

.diagonal-line::before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom right,
    transparent 49%,
    black 50%,
    transparent 51%
  );
  display: block;
}

.horizontal-line {
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  background-color: black;
  transform: translateY(-50%);
}

.reverse-diagonal-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
}

.reverse-diagonal-line::before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top right,
    transparent 49%,
    black 50%,
    transparent 51%
  );
  display: block;
}

.warnings-section {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
}

.warning-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 30px;
  color: white;
  background-color: #607d8b;
  border-radius: 50%;
  cursor: pointer;
}

.warning-block.highlighted {
  color: black;
}

.warning-block-category-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 30px;
  color: white;
  background-color: #2196f3;
  border-radius: 20%;
  cursor: pointer;
}
.warning-block-category-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 30px;
  color: white;
  background-color: #3f51b5;
  border-radius: 20%;
  cursor: pointer;
}

.penalty-block.highlighted {
  background-color: yellow;
  color: black;
}

.highlighted {
  background-color: yellow;
}

.circle-button.highlighted {
  background-color: yellow;
  color: black;
}

.total-score-box {
  border: 1px solid gray;
  color: black;
  background-color: lightgray;
  padding: 2px 10px;
  margin: 0 5px;
}

.winner {
  background-color: #4caf50;
  color: white;
}

.lose {
  background-color: #f44336;
  color: white;
}

.undo-button {
  background-color: #ffa726;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
  height: 30px;
}

.undo-button:hover {
  background-color: #fb8c00;
}

.print-button {
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #5adaff 0,
    #5468ff 100%
  );
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
  height: 30px;
}

.print-button:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.timing-display {
  font-size: 25px;
  font-weight: bold;
  color: #ffffff;
  background-color: #2c3e50;
  padding: 6px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: background-color 0.3s ease;
}

.timing-display:hover {
  background-color: #34495e;
}

.scoreboard-container {
  background-color: black;
  color: #fff;
  height: 100%;
  position: relative;
}


.team-section {
  display: flex;
  flex-direction: column;
  height: 100%;

}

.red-team {
  background-color: #C00000;
}

.blue-team {
  background-color: #0000C0;
}

.flag-icon {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

.score-circle {
  margin: 20px 0;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.team-text {
  font-size: 60px;
  color: black;
  font-weight: 700;
  padding: 0px 20px;
  background-color: white;
  border-radius: 20px;
}

.team-name-code {
  font-size: 90px;
  color: white;
  font-weight: 700;
  padding: 0px 30px;
  border-radius: 20px;
}

.score-text {
  font-size: 250px;
  color: #fff;
  font-weight: bold;
  padding: 0px 100px;
  margin: -100px 0px -30px 0px;
}

.license-text {
  margin-top: 10px;
}

.time-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.timer {
  font-size: 150px;
  color: black;
  font-weight: 700;
  padding: 0px 30px;
  background-color: white;
  border-radius: 20px;
}

.penalties-section {
  background-color: #222;
  color: #fff;
  height: 13%;
  align-items: center;
  justify-content: space-evenly;
}

.penalty-category {
  height: 17%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 40px;
  margin-top: 25px;
}

.penalty-block {
  margin: 0px 10px;
  font-size: 35px;
  padding: 15px 15px;
  border: 2px solid #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  height: 50%;
}

.penalty-block-panel {
  margin: 0 10px;
  font-size: 1.5em;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: 600;
  cursor: pointer;
}

.penalty-block-panel:hover {
  background-color: lightgray;
}

.circle-category-blue {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 15px 35px; 
  background-color: rgba(0, 0, 0, 0.6);
  padding: 15px 15px;  
  border-radius: 10px;

}

.circle-blue {
  width: 45px;
  height: 45px;
  margin: 0 22px;
  padding: 15px 15px;
  border: 2px solid rgba(0, 0, 192, 0.8);
  border-radius: 50%;
  background-color: rgba(0, 0, 192, 0.4);
}
.circle-category-red {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 15px 40px; 
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px 20px;  
  border-radius: 10px;

}

.circle-red {
  width: 45px;
  height: 45px;
  margin: 0 20px;
  padding: 15px 15px;
  border: 2px solid rgba(190, 0, 0, 0.8);
  border-radius: 50%;
  background-color: rgba(190, 0, 0, 0.4);
}

.filled {
  background-color: yellow; /* Color when filled */
}

.player-name-score-drawer {
  font-size: 40px;
  color: #fff;
  font-weight: 700;
}

.red-team-button {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  color: white;
}

.blue-team-button {
  background-color: blue;
  border-color: blue;
  color: white;
}

.red-team-button:hover {
  background-color: #ff7875;
  border-color: #ff7875;
  cursor: pointer;
}

.blue-team-button:hover {
  background-color: #238ade;
  border-color: #40a9ff;
  cursor: pointer;
}

/* print css */

@media print {
  .grid-container-print {
    display: grid;
    grid-template-columns: repeat(20, 19px);
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
    width: max-content;
    margin-left: 5px;
  }

  .number-block-print {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 19px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
  }

  .diagonal-line-print,
  .reverse-diagonal-line-print,
  .horizontal-line-print {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
  }

  .diagonal-line-print::before,
  .reverse-diagonal-line-print::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background-image: linear-gradient(
      to bottom right,
      transparent 45%,
      black 50%,
      transparent 55%
    );
  }

  .reverse-diagonal-line-print::before {
    background-image: linear-gradient(
      to top right,
      transparent 45%,
      black 50%,
      transparent 55%
    );
  }

  .horizontal-line-print {
    top: 9px;
    height: 1px;
    background-color: black;
    width: 100%;
  }
}
